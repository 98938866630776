.App {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px;
  display: block;
}
